import React from 'react';
import { Container } from 'semantic-ui-react';

import Layout from '../layouts/baseLayout';

const Blog = () => (
  <Layout>
    <Container textAlign="center">
      誠意製作中
    </Container>
  </Layout>
);

export default Blog;
